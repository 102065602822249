import * as React from "react"
import Website from "@src/components/wrappers/website"

const seo = {
    url: "/stripe/close",
    title: "Stripe closed",
    description: "",
    noindex: true
}

export default class Close extends React.Component {
    render() {
        return (
            <Website seo={seo}>
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity">
                            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
                        <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xs sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                            <div className="pt-4 pb-3">
                                <div className="text-center">
                                    <p className="text-sm leading-5 text-gray-500 px-4">
                                        You can close this window and <br />return to Google Sheets.
                                </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Website>
        )
    }
}